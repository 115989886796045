import {
    Abstract
} from './Abstract';
export class DTPConcept extends Abstract {
    constructor(session) {
        super('accountsreceivable/DTPConcept', session);
        this.name = null;
        this.key_name = null;
        this.id_tax = null;
        this.type = 1;
        this.id_ledger_account = null;
    }
}