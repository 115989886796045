import {
    Abstract
} from './Abstract';
export class Tax extends Abstract {
    constructor(session) {
        super('accountsreceivable/Tax', session);
        this.name = null;
        this.key_name = null;
        this.rate = 0.00;
        this.active = false;
        this.type = null;
        this.id_ledger_account = null;
    }
}