<template>
  <Catalog @new="newEntity" v-model="entity" :validate="validate" name="Concepto (CXP)" :rules="rules" :headers="headers" :inputs="inputs" 
  @id_tax-clear="entity.id_tax = null" />
</template>

<script>
import Catalog from "../../../components/templates/BasicCatalog.vue";
import { DTPConcept } from "../../../models/cxp/DTPConcept";
import { AccountingLedgerAccount } from "../../../models/contabilidad/AccountingLedgerAccount";
import Session from "../../../mixins/sessionMixin";
import { HeaderGrid, Rule, CatalogInput, ValidateForm } from '../../../utilities/General';
import { Tax } from '../../../models/cxp/Tax';

export default {
  data() {
    return {
      entity: new DTPConcept(),
      inputs: [
        new CatalogInput("Nombre", "name", "text", { cols: 6 }),
        new CatalogInput("Clave", "key_name", "text",  { cols: 6 }),
        new CatalogInput("Tipo", "type", "Dropdown", { cols: 4, props: { optionLabel: "name", optionValue: "id", options: [
          { id: 1, name: "GASTO" },
          { id: 2, name: "INGRESO" }
        ] }}),
        new CatalogInput("Cuenta Contable", "id_ledger_account", "Dropdown", { cols: 4, props: { filter: true, optionLabel : "name", optionValue : "id", 
        options: [{id: 0, name: '220302'}]} }),
        new CatalogInput("Impuesto", "id_tax", "dropdown", { col: 4, options: [], options_label: "name", options_value: "id"}),
      ],
      rules: [
        new Rule({ name: "name" }),
        new Rule({ name: "key_name" })
      ],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Clave", "key_name"),
        new HeaderGrid("Tipo", "type_name", { type:"upper", formula: "search", expression: "find", data: [{ id: 1, name: "GASTO" }, { id: 2, name: "INGRESO" }], data_key: "id", data_search_key: "type", data_value: "name"})
      ],
      validate: new ValidateForm()
    }
  },
  mixins: [Session],
  components: { Catalog },
  methods: {
    newEntity() {
      this.entity = new DTPConcept(this.session); 
      this.validate.clear();
    },
  },
  async created() {
    /*
                if (options.data) 
            {
                this.data = options.data;
                this.data_key = options.data_key;
                this.data_search_key = options.data_search_key;
                this.data_value = options.data_value;
            }
    */
    this.validate = new ValidateForm(this.rules);
    this.entity = new DTPConcept(this.session); 
    this.inputs[4].options = await new Tax(this.session).all();
    this.inputs[3].props.options = await new AccountingLedgerAccount(this.session).all();
    this.inputs[3].props.options = this.inputs[3].props.options.map(option => {
      return {
        id: option.id,
        name: option.id_key + ' - ' + option.name
      }
    })
  }
}
</script>

<style>

</style>